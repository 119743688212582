import React from "react";
import {
  Nav,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Loader,
  Button,
  FormInput,
  FormSelect,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";

import Folder from "../../components/home/Folder";
import ModalInsertFolder from "../../components/home/ModalInsertFolder";
import ModalEditFolder from "../../components/home/ModalEditFolder";

import axiosConfig from "../../utils/axiosConfig";
import { getCompaniesOptions } from "../../utils/general";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      tmpFolders: [],
      folders: [],
      isModalInsertFolder: false,
      isModalEditFolder: false,
      modalEditIdx: null,
      reference: "",
      dateStart: "",
      dateEnd: "",
      licensePlate: "",
      insuredName: "",
      company: "DEFAULT",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.filterFolders
    );
  };

  componentDidMount() {
    this.getFolders();
  }

  filterFolders = () => {
    let folders = this.state.tmpFolders.filter(
      (element) =>
        (!this.state.reference ||
          element.reference.includes(this.state.reference)) &&
        (!this.state.dateStart ||
          moment(element.date.split(" ")[0], "DD/MM/YYYY").isSameOrAfter(
            moment(this.state.dateStart, "YYYY-MM-DD")
          )) &&
        (!this.state.dateEnd ||
          moment(element.date.split(" ")[0], "DD/MM/YYYY").isSameOrBefore(
            moment(this.state.dateEnd, "YYYY-MM-DD")
          )) &&
        (!this.state.licensePlate ||
          element.licensePlate.includes(this.state.licensePlate)) &&
        (!this.state.insuredName ||
          element.insuredName.includes(this.state.insuredName)) &&
        (this.state.company === "DEFAULT" ||
          element.company.toString() === this.state.company)
    );

    this.setState({ folders });
  };

  // start getFolders

  getFolders = () => {
    axiosConfig("folder/get-folders")
      .then((res) => {
        if (res.data.success) {
          this.setState(
            { isLoad: false, tmpFolders: res.data.folders },
            this.filterFolders
          );
        } else if (res.data.err === "no result") {
          this.setState({ isLoad: false, tmpFolders: res.data.folders });
        } else {
          this.getFolderError();
        }
      })
      .catch(this.getFolderError);
  };

  getFolderError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez actualiser la page")
    );
  };

  // end getFolders

  // start deleteFolder

  deleteFolder = (idx) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/delete-folder", {
        data: {
          id: this.state.folders[idx].id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            let tmpFolders = this.state.folders;
            tmpFolders.splice(idx, 1);
            this.setState({ isLoad: false, tmpFolders }, this.filterFolders);
          } else {
            this.deleteFolderError();
          }
        })
        .catch(this.deleteFolderError);
    });
  };

  deleteFolderError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end deleteFolder

  // start closeFolder

  closeFolder = (idx) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/update-status-folder", {
        data: {
          id: this.state.folders[idx].id,
          status: 4,
        },
      })
        .then((res) => {
          if (res.data.success) {
            let tmpFolders = this.state.folders;
            tmpFolders[idx].status = 4;
            this.setState({ isLoad: false, tmpFolders }, this.filterFolders);
          } else {
            this.closeFolderError();
          }
        })
        .catch(this.closeFolderError);
    });
  };

  closeFolderError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end closeFolder

  render() {
    return (
      <>
        <Nav title="Accueil" className="mb-10 mb-20-xl">
          <Button
            className="ml-5"
            text="Nouveau dossier"
            onClick={() => this.setState({ isModalInsertFolder: true })}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Nav>
        <Card title="Filtres" className="mb-10 mb-20-xl">
          <Row>
            <Col xs={12} md={3}>
              <FormInput
                className="mb-20"
                title="N° dossier"
                value={this.state.reference}
                name="reference"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                className="mb-20"
                type="date"
                title="Date de début"
                value={this.state.dateStart}
                name="dateStart"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                className="mb-20"
                type="date"
                title="Date de fin"
                value={this.state.dateEnd}
                name="dateEnd"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                className="mb-20"
                title="Immatriculation"
                value={this.state.licensePlate}
                name="licensePlate"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormInput
                className="mb-20"
                title="Client"
                value={this.state.insuredName}
                name="insuredName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3}>
              <FormSelect
                className="mb-20"
                title="Compagnie"
                value={this.state.company}
                name="company"
                ignore={true}
                options={[
                  { text: "Toutes les compagnies", value: "DEFAULT" },
                ].concat(getCompaniesOptions())}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        </Card>
        <Card
          title="Liste des dossiers"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
        >
          <Table
            className="tableCustom"
            empty={!this.state.folders.length ? "Aucun dossier" : ""}
          >
            <Thead>
              <Tr>
                <Th>N° dossier</Th>
                <Th>Date</Th>
                <Th>Immat.</Th>
                <Th>RDV</Th>
                <Th>Client</Th>
                <Th>Téléphone</Th>
                <Th>E-mail</Th>
                <Th>Companie</Th>
                <Th>Statut</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.folders.map((element, idx) => (
                <Folder
                  key={idx}
                  idx={idx}
                  history={this.props.history}
                  id={element.id}
                  reference={element.reference}
                  token={element.token}
                  status={element.status}
                  date={element.date}
                  licensePlate={element.licensePlate}
                  insuredName={element.insuredName}
                  insuredPhone={element.insuredPhone}
                  insuredEmail={element.insuredEmail}
                  company={element.company}
                  dateAppointment={element.dateAppointment}
                  hourAppointment={element.hourAppointment}
                  getFolders={this.getFolders}
                  openModalEdit={() =>
                    this.setState({
                      isModalEditFolder: true,
                      modalEditIdx: idx,
                    })
                  }
                  deleteFolder={this.deleteFolder}
                  closeFolder={this.closeFolder}
                />
              ))}
            </Tbody>
          </Table>
        </Card>
        <ModalInsertFolder
          onClose={(isReload) => {
            if (isReload) {
              this.getFolders();
            }
            this.setState({ isModalInsertFolder: false });
          }}
          show={this.state.isModalInsertFolder}
        />
        <ModalEditFolder
          onClose={(isReload) => {
            if (isReload) {
              this.getFolders();
            }
            this.setState({ isModalEditFolder: false });
          }}
          show={this.state.isModalEditFolder}
          folder={this.state.folders[this.state.modalEditIdx]}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
