import React from "react";
import {
  Modal,
  FormTextarea,
  Button,
  Loader,
  FormSelect,
  FormInput,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

export default class ModalSendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      email: "DEFAULT",
      otherEmail: "",
      messageEmail: "",
      messageEmailRepairer: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.messageEmail !== this.props.messageEmail ||
      prevProps.messageEmailRepairer !== this.props.messageEmailRepairer
    ) {
      this.setState({
        messageEmail: this.props.messageEmail,
        messageEmailRepairer: this.props.messageEmailRepairer,
      });
    }
  }

  // start sendEmail

  sendEmail = () => {
    const isRepairer = this.props.repairerEmail === this.state.email;

    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/send-email", {
        data: {
          id: this.props.id,
          token: this.props.token,
          email:
            this.state.phoneNumber === "Autre"
              ? this.state.otherEmail
              : this.state.email,
          messageEmail: isRepairer
            ? this.state.messageEmailRepairer
            : this.state.messageEmail,
          agencyEmail: this.props.agencyEmail,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                this.props.getFolder(false);
                alert("Email envoyé");
                this.props.onClose();
              }
            );
          } else {
            this.sendEmailError();
          }
        })
        .catch(this.sendEmailError);
    });
  };

  sendEmailError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end sendEmail

  render() {
    const isRepairer = this.props.repairerEmail === this.state.email;
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title={`Envoyer l'email `}
        >
          <FormSelect
            className="mb-20"
            title="Destinataire"
            value={this.state.email}
            name="email"
            ignore={true}
            options={[
              { text: "Choix du destinataire", value: "DEFAULT" },
              {
                text: `Assuré : ${this.props.insuredEmail}`,
                value: this.props.insuredEmail,
              },
              {
                text: `Réparateur : ${this.props.repairerEmail}`,
                value: this.props.repairerEmail,
              },
              {
                text: `Autre`,
                value: "Autre",
              },
            ]}
            onChange={this.handleInputChange}
          />
          {this.state.email === "Autre" && (
            <FormInput
              className="mb-20"
              title="E-mail personnalisé"
              value={this.state.otherEmail}
              name="otherEmail"
              onChange={this.handleInputChange}
            />
          )}
          <FormTextarea
            className="mb-20"
            name={isRepairer ? "messageEmailRepairer" : "messageEmail"}
            value={
              isRepairer
                ? this.state.messageEmailRepairer
                : this.state.messageEmail
            }
            onChange={this.handleInputChange}
            rows={10}
          />
          <Row>
            <Col xs={12} md={6}>
              <Button
                outline
                className="mb-20"
                text="Annuler"
                onClick={() => this.props.onClose()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button
                className="mb-20"
                text="Confirmer l'envoi"
                disabled={
                  !this.state.messageEmail ||
                  this.state.email === "DEFAULT" ||
                  (this.state.email === "Autre" && !this.state.otherEmail)
                }
                onClick={() => this.sendEmail()}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Col>
          </Row>
        </Modal>
        <Loader />
      </>
    );
  }
}
