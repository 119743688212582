import React from "react";

export default class StatusWebRTC extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          left: "15px",
          bottom: "15px",
        }}
      >
        <div
          className={this.props.isPeer ? "bg-green" : "bg-red"}
          style={{ height: "15px", width: "15px", borderRadius: "15px" }}
        ></div>
      </div>
    );
  }
}
