import React from "react";
import { Status, Tr, Th, Td, ButtonIcon } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faFlag } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { getStatus, getCompanyName } from "../../utils/general";

export default class Folder extends React.Component {
  handleOpenFolderClick(e) {
    if (
      !e.target.matches(".not-click") &&
      !e.target.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.parentNode.matches(".not-click")
    ) {
      if (this.props.history) {
        this.props.history.push(`/folder/${this.props.token}`);
      }
    }
  }

  render() {
    return (
      <Tr
        className="cursor-pointer"
        onClick={(e) => this.handleOpenFolderClick(e)}
      >
        <Th className="nowrap">{this.props.reference}</Th>
        <Td className="nowrap">{this.props.date}</Td>
        <Td className="nowrap">{this.props.licensePlate}</Td>
        <Td className="nowrap">
          {moment(this.props.dateAppointment).format("DD/MM/YYYY")}{" "}
          {this.props.hourAppointment}
        </Td>
        <Td className="nowrap">{this.props.insuredName}</Td>
        <Td className="nowrap">{this.props.insuredPhone}</Td>
        <Td className="nowrap">{this.props.insuredEmail}</Td>
        <Td className="nowrap">{getCompanyName(this.props.company)}</Td>
        <Td className="nowrap">
          <Status className="w-100" text={getStatus(this.props.status)} />
        </Td>
        <Td className="nowrap not-click">
          <ButtonIcon small className="blue" onClick={this.props.openModalEdit}>
            <FontAwesomeIcon icon={faPen} />
          </ButtonIcon>
          <ButtonIcon
            small
            className="red"
            onClick={() => this.props.deleteFolder(this.props.idx)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
          {this.props.status < 4 ? (
            <ButtonIcon
              small
              className="green"
              onClick={() => this.props.closeFolder(this.props.idx)}
            >
              <FontAwesomeIcon icon={faFlag} />
            </ButtonIcon>
          ) : (
            <></>
          )}
        </Td>
      </Tr>
    );
  }
}
