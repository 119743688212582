import React, { createRef } from "react";
import Peer from "peerjs";
import {
  faPhoneSlash,
  faCamera,
  faPen,
  faTrash,
  faSyncAlt,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faVideo,
  faVideoSlash,
  faServer,
} from "@fortawesome/free-solid-svg-icons";

import StatusWebRTC from "./StatusWebRTC";
import CallAlertWebRTC from "./CallAlertWebRTC";
import Camera from "./Camera";
import ButtonIcon from "./ButtonIcon";

import axiosConfig from "../../utils/axiosConfig";
import configPeer from "../../utils/configPeer";
import {
  constraints,
  startCamera,
  stopCamera,
  updateAudio,
  updateVideo,
} from "../../utils/camera";

import soundWAW from "../../sound/call.wav";

import AuthContext from "../../context/AuthContext";

export default class WebRTC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPeer: false,
      isCamera: false,
      isCall: false,
      isMarker: false,
      isMuted: false,
      isCameraDisabled: false,
      token: null,
      localVideoWidth: null,
      localVideoHeight: null,
      remoteVideoWidth: null,
      remoteVideoHeight: null,
      isTakePicture: false,
      pictures: [],
      statusConnection: 0,
    };

    // WebRTC constraints
    this.constraints = constraints;
    // Components ref
    this.cameraRef = createRef();
    this.localVideoRef = createRef();
    this.remoteVideoRef = createRef();
    // Call sound
    this.audio = new Audio(soundWAW);
  }

  static contextType = AuthContext;

  setDefaultState = (isPeer, callback = () => {}) => {
    this.setState(
      {
        isPeer: isPeer,
        isCamera: false,
        isCall: false,
        isMarker: false,
        isMuted: false,
        isCameraDisabled: false,
        token: null,
        localVideoWidth: null,
        localVideoHeight: null,
        remoteVideoWidth: null,
        remoteVideoHeight: null,
        isTakePicture: false,
        statusConnection: 0,
      },
      callback
    );
  };

  componentDidMount() {
    if (!document.location.href.includes("client")) {
      this.initPeer();
    }
  }

  initPeer = () => {
    this.peer = new Peer(`xpertvisio_${this.context.id}_expert`, configPeer);

    this.peer.on("open", () => {
      this.setState({ isPeer: true });
    });

    this.peer.on("connection", (conn) => {
      this.conn = conn;
      this.conn.on("data", (data) => {
        switch (data.type) {
          case "call":
            if (!this.state.isCall) {
              this.setState({ token: data.token, isCall: true }, () => {
                this.audio.currentTime = 0;
                this.audio.play();
              });
            }
            break;
          case "call-reconnect":
            if (!this.state.token === data.token) {
              this.handleAcceptClick();
            }
            break;
          case "close-call":
            this.handleStopCameraClick();
            break;
          case "return-picture":
            this.getPicture(data.id);
            break;
          default:
            break;
        }
      });
    });

    this.peer.on("disconnect", () => {
      console.log("disconnect");
      this.setState({ isPeer: false });
      // this.setDefaultState(false);
    });
    this.peer.on("error", (err) => {
      console.log("error", err);
      this.setState({ isPeer: false }, () => {
        setTimeout(() => {
          this.initPeer();
        }, 10000);
      });
      // this.setDefaultState(false);
    });
  };

  // start getPicture

  getPicture = (id) => {
    axiosConfig("folder/get-picture", {
      data: {
        id,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          this.getPictureError();
        } else {
          let tmpPictures = this.state.pictures;
          tmpPictures.push({
            id,
            base64: res.data.picture.base64,
          });
          this.setState({ pictures: tmpPictures });
        }
      })
      .catch(this.getPictureError);
  };

  getPictureError = () => {
    alert("Une erreur est survenue lors de la suppression de l'image");
  };

  // end getPicture

  // start deletePicture

  handleDeletePictureClick = (key) => {
    let tmpPictures = this.state.pictures;
    const id = tmpPictures[key].id;
    tmpPictures.splice(key, 1);
    this.setState({ pictures: tmpPictures }, () => {
      axiosConfig("folder/delete-picture", {
        data: {
          id,
        },
      })
        .then((res) => {
          if (!res.data.success) {
            this.deletePictureError();
          }
        })
        .catch(this.deletePictureError);
    });
  };

  deletePictureError = () => {
    alert("Une erreur est survenue lors de la suppression de l'image");
  };

  // end deletePicture

  handleStartCallClick = () => {
    this.call = this.peer.call(
      `${this.state.token}_client`,
      this.localVideoRef.current.srcObject
    );

    this.call.on("stream", (remoteStream) => {
      console.log("stream");
      this.remoteVideoRef.current.srcObject = remoteStream;
      this.remoteVideoRef.current.addEventListener(
        "playing",
        this.playingRemoteEventListener,
        false
      );
    });

    this.call.peerConnection.oniceconnectionstatechange = () => {
      console.log(this.call.peerConnection.iceConnectionState);
      if (this.call.peerConnection.iceConnectionState === "disconnected") {
        this.setState({ statusConnection: 0 });
      } else if (this.call.peerConnection.iceConnectionState === "connected") {
        this.setState({ statusConnection: 2 });
      } else if (this.call.peerConnection.iceConnectionState === "completed") {
        this.setState({ statusConnection: 2 });
      }
    };
  };

  playingLocalEventListener = () => {
    this.setState(
      {
        localVideoWidth: this.localVideoRef.current.videoWidth,
        localVideoHeight: this.localVideoRef.current.videoHeight,
      },
      () => {
        this.handleStartCallClick();
      }
    );
  };

  playingRemoteEventListener = () => {
    this.setState({
      remoteVideoWidth: this.remoteVideoRef.current.videoWidth,
      remoteVideoHeight: this.remoteVideoRef.current.videoHeight,
    });
  };

  handleAcceptClick = () => {
    this.audio.pause();
    startCamera(this.constraints, (result) => {
      if (result.success) {
        this.setState({ isCamera: true }, () => {
          window.stream = result.stream;
          this.localVideoRef.current.srcObject = result.stream;
          this.localVideoRef.current.addEventListener(
            "playing",
            this.playingLocalEventListener,
            false
          );
        });
      }
    });
  };

  handleRejectClick = () => {
    this.audio.pause();
    this.conn.send({ type: "reject-call" });
    this.setState({
      token: null,
      isCall: false,
      isCamera: false,
    });
  };

  handleStopCameraClick = (isSend = false) => {
    stopCamera(() => {
      const token = this.state.token;
      this.setDefaultState(true, () => {
        if (isSend) {
          if (this.conn) this.conn.send({ type: "close-call" });
        } else {
          if (this.call) this.call.close();
          if (this.conn) this.conn.close();
        }
        document.location = `/folder/${token}`;
      });
    });
  };

  handleTakePictureClick = () => {
    this.setState({ isTakePicture: false }, () =>
      this.setState({ isTakePicture: true }, () => {
        this.conn.send({ type: "take-picture" });
      })
    );
  };

  handlePointMarkerClick = (x, y, width, height) => {
    this.conn.send({ type: "point-marker", x, y, width, height });
  };

  handleClearMarkerClick = () => {
    this.cameraRef.current.clearCanvas();
    this.conn.send({ type: "clear-marker" });
  };

  handleUpdateAudioClick = () => {
    updateAudio(this.state.isMuted);
    this.setState({
      isMuted: !this.state.isMuted,
    });
  };

  handleUpdateVideoClick = () => {
    updateVideo(this.state.isCameraDisabled);
    this.setState({
      isCameraDisabled: !this.state.isCameraDisabled,
    });
  };

  handleReverseCameraClick = () => {
    this.conn.send({ type: "reverse-camera" });
  };

  render() {
    if (!document.location.href.includes("client")) {
      return (
        <div style={{ position: "absolute", zIndex: 100, height: 0, width: 0 }}>
          <StatusWebRTC isPeer={this.state.isPeer} />
          {this.state.token ? (
            <CallAlertWebRTC
              handleRejectClick={this.handleRejectClick}
              handleAcceptClick={this.handleAcceptClick}
              token={this.state.token}
              isCall={this.state.isCall}
            />
          ) : null}
          {this.state.isCamera ? (
            <Camera
              ref={this.cameraRef}
              secondVideoWidth={this.state.localVideoWidth}
              secondVideoHeight={this.state.localVideoHeight}
              secondVideoRef={this.localVideoRef}
              secondVideoIsMuted={true}
              firstVideoWidth={this.state.remoteVideoWidth}
              firstVideoHeight={this.state.remoteVideoHeight}
              firstVideoRef={this.remoteVideoRef}
              isMarker={this.state.isMarker}
              onMarker={this.handlePointMarkerClick}
              isTakePicture={this.state.isTakePicture}
              pictures={this.state.pictures}
              onDeletePicture={this.handleDeletePictureClick}
              statusConnection={this.state.statusConnection}
            >
              <ButtonIcon
                onClick={() => this.handleStopCameraClick(true)}
                icon={faPhoneSlash}
                red={true}
              />
              <ButtonIcon
                onClick={() => this.handleTakePictureClick()}
                icon={faCamera}
              />
              <ButtonIcon
                onClick={() => {
                  this.setState({ isMarker: !this.state.isMarker });
                }}
                green={this.state.isMarker}
                icon={faPen}
              />
              <ButtonIcon
                onClick={this.handleClearMarkerClick}
                icon={faTrash}
              />
              <ButtonIcon
                onClick={this.handleUpdateAudioClick}
                red={this.state.isMuted}
                icon={
                  this.state.isMuted ? faMicrophoneAltSlash : faMicrophoneAlt
                }
              />
              <ButtonIcon
                onClick={this.handleUpdateVideoClick}
                red={this.state.isCameraDisabled}
                icon={this.state.isCameraDisabled ? faVideoSlash : faVideo}
              />
              <ButtonIcon
                onClick={this.handleReverseCameraClick}
                icon={faSyncAlt}
              />
            </Camera>
          ) : null}
        </div>
      );
    } else {
      return <></>;
    }
  }
}
