import React from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";

import { getTypeHistorical } from "../../utils/general";

export default class Historical extends React.Component {
  render() {
    return (
      <Card title="Historique" className="h-100">
        <Table className="tableCustom">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Type</Th>
              <Th>Détail</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.historical.map((element, idx) => (
              <Tr key={idx}>
                <Th className="nowrap">{element.date}</Th>
                <Td className="nowrap">{getTypeHistorical(element.type)}</Td>
                <Td>{element.detail}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    );
  }
}
