import React from "react";
import { Modal, Status } from "ui-kit-ck-consultant";
import moment from "moment";

import { getStatus, getCompanyName } from "../../utils/general";

export default class ModalInformationWebRTC extends React.Component {
  render() {
    return (
      <Modal
        title={<Status text={getStatus(this.props.status)} />}
        show={this.props.isModal}
        onClose={this.props.onClose}
      >
        <p>
          dossier N° : <b>{this.props.reference}</b>
        </p>
        <p>
          Date : <b>{this.props.date}</b>
        </p>
        <p>
          Immatriculation : <b>{this.props.licensePlate}</b>
        </p>
        <p>
          Marque : <b>{this.props.brand}</b>
        </p>
        <p>
          Modèle : <b>{this.props.model}</b>
        </p>
        <p>
          Nom : <b>{this.props.insuredName}</b>
        </p>
        <p>
          Téléphone : <b>{this.props.insuredPhone}</b>
        </p>
        <p>
          E-mail : <b>{this.props.insuredEmail}</b>
        </p>
        <p>
          Compagnie : <b>{getCompanyName(this.props.company)}</b>
        </p>
        <p>
          RDV : <b>{moment(this.props.dateAppointment).format("DD/MM/YYYY")} {this.props.hourAppointment}</b>
        </p>
      </Modal>
    );
  }
}
