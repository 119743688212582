import React from "react";
import {
  Modal,
  FormTextarea,
  Button,
  Loader,
  FormCheckbox,
  FormSelect,
  FormInput,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class ModalSendSMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      messageSMS: "",
      messageSMSRepairer: "",
      phoneNumber: "DEFAULT",
      otherPhoneNumber: "",
      isProgramm: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.messageSMS !== this.props.messageSMS ||
      prevProps.messageSMSRepairer !== this.props.messageSMSRepairer
    ) {
      this.setState({
        messageSMS: this.props.messageSMS,
        messageSMSRepairer: this.props.messageSMSRepairer,
      });
    }
  }

  // start sendSMS

  sendSMS = () => {
    const isRepairerMessage =
      this.props.repairerPhone === this.state.phoneNumber;
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/send-sms", {
        data: {
          id: this.props.id,
          token: this.props.token,
          phoneNumber:
            this.state.phoneNumber === "Autre"
              ? this.state.otherPhoneNumber
              : this.state.phoneNumber,
          groupName: this.context.groupName,
          messageSMS: isRepairerMessage
            ? this.state.messageSMSRepairer
            : this.state.messageSMS,
          dateAppointment: this.props.dateAppointment,
          hourAppointment: this.props.hourAppointment,
          isProgramm: this.state.isProgramm,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                this.props.getFolder(false);
                alert("SMS envoyé");
                this.props.onClose();
              }
            );
          } else {
            this.sendSMSError();
          }
        })
        .catch(this.sendSMSError);
    });
  };

  sendSMSError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end sendSMS

  render() {
    const isRepairerMessage =
      this.props.repairerPhone === this.state.phoneNumber;
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title={`Envoyer un SMS`}
        >
          <FormSelect
            className="mb-20"
            title="Destinataire"
            value={this.state.phoneNumber}
            name="phoneNumber"
            ignore={true}
            options={[
              { text: "Choix du destinataire", value: "DEFAULT" },
              {
                text: `Assuré : ${this.props.insuredPhone}`,
                value: this.props.insuredPhone,
              },
              {
                text: `Réparateur : ${this.props.repairerPhone}`,
                value: this.props.repairerPhone,
              },
              {
                text: `Autre`,
                value: "Autre",
              },
            ]}
            onChange={this.handleInputChange}
          />
          {this.state.phoneNumber === "Autre" && (
            <FormInput
              className="mb-20"
              title="Numéro personnalisé"
              value={this.state.otherPhoneNumber}
              name="otherPhoneNumber"
              onChange={this.handleInputChange}
            />
          )}
          <FormTextarea
            className="mb-20"
            name={isRepairerMessage ? "messageSMSRepairer" : "messageSMS"}
            value={
              isRepairerMessage
                ? this.state.messageSMSRepairer
                : this.state.messageSMS
            }
            onChange={this.handleInputChange}
            rows={10}
          />
          <Row>
            <Col xs={12}>
              <FormCheckbox
                className="mb-20"
                text="Envoi différé"
                name="isProgramm"
                checked={this.state.isProgramm}
                onChange={this.handleCheckboxChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                outline
                className="mb-20"
                text="Annuler"
                onClick={() => this.props.onClose()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button
                className="mb-20"
                text="Confirmer l'envoi"
                disabled={
                  !this.state.messageSMS ||
                  this.state.phoneNumber === "DEFAULT" ||
                  (this.state.phoneNumber === "Autre" &&
                    !this.state.otherPhoneNumber)
                }
                onClick={() => this.sendSMS()}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Col>
          </Row>
        </Modal>
        <Loader />
      </>
    );
  }
}
