import React from "react";
import { ButtonIcon } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPhone,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";

import ModalInformationWebRTC from "./ModalInformationWebRTC";

import axiosConfig from "../../utils/axiosConfig";

export default class CallAlertWebRTC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      id: null,
      reference: null,
      status: null,
      date: null,
      licensePlate: null,
      brand: null,
      model: null,
      insuredName: null,
      insuredPhone: null,
      insuredEmail: null,
      company: null,
    };
  }

  componentDidMount() {
    this.getFolder();
  }

  // start getFolder

  getFolder = () => {
    axiosConfig("folder/get-folder", {
      data: { token: this.props.token },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            id: res.data.folder.id,
            reference: res.data.folder.reference,
            status: res.data.folder.status,
            date: res.data.folder.date,
            licensePlate: res.data.folder.licensePlate,
            brand: res.data.folder.brand,
            model: res.data.folder.model,
            insuredName: res.data.folder.insuredName,
            insuredPhone: res.data.folder.insuredPhone,
            insuredEmail: res.data.folder.insuredEmail,
            company: res.data.folder.company,
            dateAppointment: res.data.folder.dateAppointment,
            hourAppointment: res.data.folder.hourAppointment,
          });
        } else {
          this.getFolderError();
        }
      })
      .catch(this.getFolderError);
  };

  getFolderError = () => {
    this.setState({ isLoad: false }, () => {
      alert("Une erreur est survenue");
    });
  };

  // end getFolder

  render() {
    if (this.props.isCall) {
      return (
        <div
          style={{
            position: "fixed",
            right: "15px",
            bottom: "15px",
          }}
        >
          <div
            className="mt-10"
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "15px",
              boxShadow: "0 3px 6px 0 rgb(0 0 0 / 16%)",
              padding: "1rem",
            }}
          >
            <div className="d-flex">
              <p
                style={{
                  margin: "auto",
                  marginLeft: 0,
                  marginRight: "1rem",
                }}
              >
                N° de dossier: <b>{this.state.reference}</b>
              </p>
              <ButtonIcon
                small
                className="blue"
                style={{
                  margin: "auto",
                  marginLeft: "1rem",
                  marginRight: 0,
                }}
                onClick={() => this.setState({ isModal: true })}
              >
                <FontAwesomeIcon icon={faInfo} />
              </ButtonIcon>
            </div>
            <div className="d-flex">
              <ButtonIcon
                small
                className="red"
                onClick={this.props.handleRejectClick}
              >
                <FontAwesomeIcon icon={faPhoneSlash} />
              </ButtonIcon>
              <ButtonIcon
                small
                className="green"
                onClick={this.props.handleAcceptClick}
              >
                <FontAwesomeIcon icon={faPhone} />
              </ButtonIcon>
            </div>
          </div>
          <ModalInformationWebRTC
            isModal={this.state.isModal}
            status={this.state.status}
            reference={this.state.reference}
            date={this.state.date}
            licensePlate={this.state.licensePlate}
            brand={this.state.brand}
            model={this.state.model}
            insuredName={this.state.insuredName}
            insuredPhone={this.state.insuredPhone}
            insuredEmail={this.state.insuredEmail}
            company={this.state.company}
            dateAppointment={this.state.dateAppointment}
            hourAppointment={this.state.hourAppointment}
            onClose={() => this.setState({ isModal: false })}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }
}
