const constraints = {
  audio: true,
  video: {
    width: { ideal: 2560 },
    height: { ideal: 1440 },
    facingMode: { ideal: "environment" },
    frameRate: { min: 25, ideal: 30, max: 30 },
  },
};

const startCamera = (constraints, callback) => {
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      callback({ success: true, stream });
    })
    .catch((error) => callback({ success: false, error }));
};

const stopCamera = (callback) => {
  if (window.stream) {
    window.stream.getTracks().forEach(function (track) {
      track.stop();
    });
    callback({ success: true });
  } else {
    callback({ success: false });
  }
};

const updateAudio = (audioMuted) => {
  window.stream.getAudioTracks()[0].enabled = audioMuted;
  return true;
};

const updateVideo = (cameraDisabled) => {
  window.stream.getVideoTracks()[0].enabled = cameraDisabled;
  return true;
};

const reverseCamera = (enabled, callback) => {
  stopCamera(() => {
    constraints.video.facingMode.ideal =
      constraints.video.facingMode.ideal === "environment"
        ? "facing"
        : "environment";
    callback(constraints);
  });
};

export {
  constraints,
  startCamera,
  stopCamera,
  updateAudio,
  updateVideo,
  reverseCamera,
};
