import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "ui-kit-ck-consultant";
export default class Camera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullModal: false,
      currentImage: "",
    };
    this.canvasRef = React.createRef();
  }

  addMarker = (x, y, width = null, height = null) => {
    if (width && height) {
      x = (x * this.props.firstVideoRef.current.clientWidth) / width;
      y = (y * this.props.firstVideoRef.current.clientHeight) / height;
    }

    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");
    context.beginPath();
    this.canvasArrow(context, x - 20, y - 20, x, y);
    context.stroke();
    this.getCanvasPNG();
  };

  clearCanvas = () => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  canvasArrow(context, fromx, fromy, tox, toy) {
    let headlen = 15;
    context.strokeStyle = "red";
    context.lineWidth = 3;
    let dx = tox - fromx;
    let dy = toy - fromy;
    let angle = Math.atan2(dy, dx);
    context.moveTo(fromx, fromy);
    context.lineTo(tox, toy);
    context.lineTo(
      tox - headlen * Math.cos(angle - Math.PI / 5),
      toy - headlen * Math.sin(angle - Math.PI / 5)
    );
    context.moveTo(tox, toy);
    context.lineTo(
      tox - headlen * Math.cos(angle + Math.PI / 5),
      toy - headlen * Math.sin(angle + Math.PI / 5)
    );
  }

  getCanvasPNG = () => {
    return this.canvasRef.current;
  };

  render() {
    const width = this.props.firstVideoRef.current
      ? this.props.firstVideoRef.current.clientWidth
      : null;
    const height = this.props.firstVideoRef.current
      ? this.props.firstVideoRef.current.clientHeight
      : null;

    if (
      !this.props.firstVideoIsMuted &&
      this.props.firstVideoRef &&
      this.props.firstVideoRef.current
    ) {
      this.props.firstVideoRef.current.volume = 0.95;
    }

    if (
      !this.props.secondVideoIsMuted &&
      this.props.secondVideoRef &&
      this.props.secondVideoRef.current
    ) {
      this.props.secondVideoRef.current.volume = 0.95;
    }

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgb(0, 0, 0)",
          zIndex: 200,
        }}
      >
        {this.props.countLoadPicture ? (
          <div
            style={{
              position: "fixed",
              left: "30px",
              bottom: "30px",
              zIndex: 1000,
            }}
          >
            <div
              className="bg-red"
              style={{ height: "15px", width: "15px", borderRadius: "15px" }}
            ></div>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            position: "absolute",
            left: "2rem",
            top: "2rem",
            height: "120px",
            width: "120px",
            borderRadius: "60px",
            border: "2px rgba(255, 255, 255, 0.4) solid",
            backgroundColor: "rgb(0, 0, 0)",
            zIndex: 10,
            overflow: "hidden",
          }}
        >
          <video
            volume={0.95}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth:
                this.props.secondVideoWidth < this.props.secondVideoHeight
                  ? "100%"
                  : "inherit",
              maxHeight:
                this.props.secondVideoHeight < this.props.secondVideoWidth
                  ? "100%"
                  : "inherit",
            }}
            src={null}
            autoPlay
            playsInline
            muted={this.props.secondVideoIsMuted}
            ref={this.props.secondVideoRef}
          />
        </div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
          }}
        >
          <video
            volume={0.95}
            className={this.props.isTakePicture ? "camera-animation" : ""}
            style={{
              margin: "auto",
              width:
                this.props.firstVideoWidth > this.props.firstVideoHeight
                  ? "100%"
                  : "inherit",
              height:
                this.props.firstVideoHeight > this.props.firstVideoWidth
                  ? "100%"
                  : "inherit",
            }}
            src={null}
            autoPlay
            playsInline
            muted={this.props.firstVideoIsMuted}
            ref={this.props.firstVideoRef}
          />
          <canvas
            ref={this.canvasRef}
            width={width}
            height={height}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: width ? width : "inherit",
              height: height ? height : "inherit",
            }}
            onClick={(e) => {
              if (this.props.isMarker) {
                let currentTargetRect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - currentTargetRect.left;
                const y = e.clientY - currentTargetRect.top;
                this.addMarker(x, y);
                this.props.onMarker(x, y, width, height);
              }
            }}
          />
        </div>
        <div
          style={{
            margin: "auto",
            marginBottom: "1rem",
            color: "#ffffff",
            zIndex: 20,
          }}
        >
          {this.props.children}
        </div>
        {this.props.pictures && this.props.pictures.length ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              width: "180px",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              zIndex: 210,
              padding: "1rem",
              overflowY: "auto",
            }}
          >
            {this.props.pictures.reverse().map((element, idx) => (
              <div
                key={idx}
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "40px",
                    height: "40px",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    cursor: "pointer",
                    color: "red",
                    borderBottomLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  onClick={() => this.props.onDeletePicture(idx)}
                >
                  <FontAwesomeIcon style={{ margin: "auto" }} icon={faTrash} />
                </div>
                <img
                  src={element.base64}
                  alt={idx}
                  style={{
                    maxWidth: "100%",
                    marginBottom: "1rem",
                    borderRadius: "10px",
                  }}
                  onClick={() =>
                    this.setState({
                      isFullModal: true,
                      currentImage: element.base64,
                    })
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
        {this.props.statusConnection === 0 ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              textAlign: "center",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            }}
          >
            <p>Recherche du partenaire...</p>
          </div>
        ) : (
          <></>
        )}
        {this.props.statusConnection === 1 ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              textAlign: "center",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            }}
          >
            <p>Connexion au partenaire...</p>
          </div>
        ) : (
          <></>
        )}
        <Modal
          show={this.state.isFullModal}
          onClose={() => this.setState({ isFullModal: false })}
        >
          <img
            src={this.state.currentImage}
            alt="full"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Modal>
      </div>
    );
  }
}
