import React from "react";
import { Card, Button, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSms, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import ModalSendEmail from "./ModalSendEmail";
import ModalSendSMS from "./ModalSendSMS";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalSendEmail: false,
      isModalSendSMS: false,
    };
  }

  render() {
    return (
      <>
        <Card title="Contact" className="h-100">
          <Button
            className="mb-10 mb-20-xl"
            text="Modifier les messages"
            disabled={this.props.status >= 4}
            onClick={this.props.openModalEdit}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button
            className="mb-10 mb-20-xl"
            outline
            text="Envoyer un E-mail"
            disabled={this.props.status > 2}
            onClick={() => this.setState({ isModalSendEmail: true })}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>
          <Button
            className="mb-10 mb-20-xl"
            outline
            text="Envoyer un SMS"
            disabled={this.props.status > 2}
            onClick={() => this.setState({ isModalSendSMS: true })}
          >
            <FontAwesomeIcon icon={faSms} />
          </Button>
        </Card>
        <ModalSendEmail
          show={this.state.isModalSendEmail}
          id={this.props.id}
          token={this.props.token}
          insuredEmail={this.props.insuredEmail}
          repairerEmail={this.props.repairerEmail}
          messageEmail={this.props.messageEmail}
          messageEmailRepairer={this.props.messageEmailRepairer}
          agencyEmail={this.props.agencyEmail}
          getFolder={this.props.getFolder}
          onClose={() =>
            this.setState({
              isModalSendEmail: false,
            })
          }
        />
        <ModalSendSMS
          show={this.state.isModalSendSMS}
          id={this.props.id}
          token={this.props.token}
          insuredPhone={this.props.insuredPhone}
          repairerPhone={this.props.repairerPhone}
          dateAppointment={this.props.dateAppointment}
          hourAppointment={this.props.hourAppointment}
          messageSMS={this.props.messageSMS}
          messageSMSRepairer={this.props.messageSMSRepairer}
          getFolder={this.props.getFolder}
          onClose={() =>
            this.setState({
              isModalSendSMS: false,
            })
          }
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
