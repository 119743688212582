import React from "react";
import { Modal, FormTextarea, Button, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class ModalEditFolderMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      messageEmail: "",
      messageEmailRepairer: "",
      messageSMS: "",
      messageSMSRepairer: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        messageEmail: this.props.messageEmail,
        messageEmailRepairer: this.props.messageEmailRepairer,
        messageSMS: this.props.messageSMS,
        messageSMSRepairer: this.props.messageSMSRepairer,
      });
    }
  }

  setDefaultMessageEmail = () => {
    const defaultMessageEmail = `Bonjour ${this.props.kind} ${
      this.props.insuredName
    },

Nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.props.brand
    } ${this.props.model} ${this.props.licensePlate} en visioconférence : [URL]

Cordialement,
${this.context.name}
${this.context.groupName ? this.context.groupName : ""}`;

    const defaultMessageEmailRepairer = `Bonjour ${this.props.repairerName},

Nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.props.brand
    } ${this.props.model} ${this.props.licensePlate} en visioconférence : [URL]

Cordialement,
${this.context.name}
${this.context.groupName ? this.context.groupName : ""}`;

    this.setState({
      messageEmail: defaultMessageEmail,
      messageEmailRepairer: defaultMessageEmailRepairer,
    });
  };

  setDefaultMessageSMS = () => {
    const defaultMessageSMS = `Bonjour ${this.props.kind} ${
      this.props.insuredName
    }, nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.props.brand
    } ${this.props.model} ${
      this.props.licensePlate
    } en visioconférence : [URL] . Cordialement, ${this.context.name} ${
      this.context.groupName ? this.context.groupName : ""
    }`;

    const defaultMessageSMSRepairer = `Bonjour ${
      this.props.repairerName
    } , nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.props.brand
    } ${this.props.model} ${
      this.props.licensePlate
    } en visioconférence : [URL] . Cordialement, ${this.context.name} ${
      this.context.groupName ? this.context.groupName : ""
    }`;
    this.setState({
      messageSMS: defaultMessageSMS,
      messageSMSRepairer: defaultMessageSMSRepairer,
    });
  };

  // start updateFolderMessage

  updateFolderMessage = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/update-folder-message", {
        data: {
          id: this.props.id,
          messageEmail: this.state.messageEmail,
          messageEmailRepairer: this.state.messageEmailRepairer,
          messageSMS: this.state.messageSMS,
          messageSMSRepairer: this.state.messageSMSRepairer,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Le dossier a été modifié");
                this.props.onClose(true);
              }
            );
          } else {
            this.updateFolderMessageError();
          }
        })
        .catch(this.updateFolderMessageError);
    });
  };

  updateFolderMessageError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez réssayer")
    );
  };

  // end updateFolderMessage

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title={`Modifier les message`}
          large
        >
          <Row>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                E-mail assuré / SMS assuré
              </p>
            </Col>
            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                name="messageEmail"
                value={this.state.messageEmail}
                onChange={this.handleInputChange}
                rows={10}
              />
            </Col>

            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                name="messageSMS"
                value={this.state.messageSMS}
                onChange={this.handleInputChange}
                rows={10}
              />
            </Col>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                E-mail Garage / SMS Garage
              </p>
            </Col>
            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                name="messageEmailRepairer"
                value={this.state.messageEmailRepairer}
                onChange={this.handleInputChange}
                rows={10}
              />
            </Col>

            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                name="messageSMSRepairer"
                value={this.state.messageSMSRepairer}
                onChange={this.handleInputChange}
                rows={10}
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                outline
                className="mb-20"
                text="Message par défaut"
                onClick={() => {
                  this.setDefaultMessageEmail();
                  this.setDefaultMessageSMS();
                }}
              >
                <FontAwesomeIcon icon={faSyncAlt} />
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button
                className="mb-20"
                text="Enregistrer"
                disabled={!this.state.messageEmail || !this.state.messageSMS}
                onClick={this.updateFolderMessage}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </Col>
          </Row>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
