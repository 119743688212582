const getStatus = (status) => {
  switch (status) {
    case 0:
      return "Nouveau";
    case 1:
      return "SMS / mail envoyé";
    case 2:
      return "Ouvert";
    case 3:
      return "En cours";
    case 4:
      return "Clôturé";
    default:
      return "Inconnu";
  }
};

const getTypeHistorical = (type) => {
  switch (type) {
    case 1:
      return "Création";
    case 2:
      return "Modification";
    case 3:
      return "Modification";
    case 4:
      return "SMS envoyé";
    case 5:
      return "Mail envoyé";
    case 6:
      return "Visioconférence";
    case 7:
      return "Visioconférence";
    case 8:
      return "Photo";
    default:
      return "Inconnu";
  }
};

const companies = [
  { name: "AUTRE", code: [] },
  { name: "ALLIANZ", code: ["295", "605", "219", "295C", "296"] },
  {
    name: "COVEA",
    code: [
      "568",
      "587",
      "251",
      "592",
      "635",
      "623",
      "492",
      "100",
      "038",
      "244",
      "043",
      "493",
      "490",
      "606",
      "492",
      "388",
      "397",
      "0043",
    ],
  },
  {
    name: "MACIF",
    code: [
      "369",
      "369A",
      "369D",
      "369G",
      "369J",
      "369K",
      "369P",
      "369S",
      "369T",
      "369V",
      "369W",
      "369X",
      "369Y",
    ],
  },
  { name: "ACM", code: ["435"] },
  { name: "MACSF", code: ["361"] },
  { name: "MUTUELLE DE POITIERS", code: ["147"] },
  { name: "MAIF", code: ["272", "372"] },
  { name: "MATMUT", code: ["418", "651", "739"] },
  { name: "GAN", code: ["297"] },
  {
    name: "GROUPAMA",
    code: [
      "813",
      "814",
      "821",
      "828",
      "831",
      "834",
      "835",
      "845",
      "851",
      "867",
      "869",
      "872",
      "875",
      "879",
      "894",
      "897",
    ],
  },
  { name: "SWISSLIFE", code: ["322", "322A"] },
  { name: "AVIVA", code: ["001", "001A"] },
  { name: "EQUITE", code: ["104"] },
  { name: "AREAS", code: ["3"] },
  { name: "GENERALI", code: ["15", "498"] },
  { name: "MUTUELLE DES MOTARDS", code: ["105"] },
  { name: "AGPM", code: ["148"] },
  { name: "AXA", code: ["276", "299", "499", "588", "799"] },
  { name: "MFA", code: ["355"] },
  { name: "AVANSUR", code: ["392"] },
  { name: "SMACL", code: ["522"] },
  { name: "BPCE", code: ["602", "602A", "602R"] },
  { name: "SURAVENIR", code: ["590"] },
  { name: "AIOI", code: ["637"] },
  { name: "L'OLIVIER", code: ["744"] },
];

const getCompaniesOptions = () => {
  let companiesOptions = [];
  companies.map((element, idx) => {
    companiesOptions.push({ text: element.name, value: idx });
    return false;
  });
  return companiesOptions;
};

const getCompanyFromCode = (code) => {
  let result = "0";
  companies.map((element, idx) => {
    if (element.code.includes(code)) {
      result = idx.toString();
    }
    return false;
  });
  return result;
};

const getCompanyName = (id) => {
  let result = "AUTRE";
  companies.map((element, idx) => {
    if (id === idx) {
      result = element.name;
    }
    return false;
  });
  return result;
};

export {
  getStatus,
  getTypeHistorical,
  getCompaniesOptions,
  getCompanyFromCode,
  getCompanyName,
};
