import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ButtonIcon extends React.Component {
  render() {
    const defaultColor = "rgba(255, 255, 255, 0.3)";
    const redColor = "rgba(255, 0, 0, 0.3)";
    const greenColor = "rgba(0, 255, 0, 0.3)";

    return (
      <div
        style={{
          display: "inline-flex",
          backgroundColor: this.props.red
            ? redColor
            : this.props.green
            ? greenColor
            : defaultColor,
          height: this.props.height ? this.props.height : "60px",
          width: this.props.width ? this.props.width : "60px",
          borderRadius: "30px",
          fontSize: this.props.fontSize ? this.props.fontSize : "16px",
          cursor: "pointer",
          margin: "0.5rem",
        }}
        onClick={this.props.onClick}
      >
        <FontAwesomeIcon style={{ margin: "auto" }} icon={this.props.icon} />
      </div>
    );
  }
}
