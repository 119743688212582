import React from "react";
import { Card, Status } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import ModalEditFolder from "../home/ModalEditFolder";

import { getStatus, getCompanyName } from "../../utils/general";
export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalEditFolder: false,
    };
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={6} className="mb-10">
          <Card
            title="Informations"
            action={
              this.props.status < 4 ? <FontAwesomeIcon icon={faPen} /> : <></>
            }
            onClick={() => this.setState({ isModalEditFolder: true })}
          >
            <Row>
              <Col xs={12} md={6} className="mb-10">
                <Status text={getStatus(this.props.status)} />
              </Col>
              <Col xs={12} md={6} lg={8}></Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  N° dossier : <b>{this.props.reference}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Compangie : <b>{getCompanyName(this.props.company)}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Marque : <b>{this.props.brand}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Modèle : <b>{this.props.model}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Immatriculation : <b>{this.props.licensePlate}</b>
                </p>
              </Col>

              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Date : <b>{this.props.date}</b>
                </p>
              </Col>

              <Col xs={12} className="mb-10">
                <p className="m-0">
                  RDV :{" "}
                  <b>
                    {moment(this.props.dateAppointment).format("DD/MM/YYYY")}{" "}
                    {this.props.hourAppointment}
                  </b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="mb-10">
          <Card
            className="h-100"
            title="Agence"
            action={
              this.props.status < 4 ? <FontAwesomeIcon icon={faPen} /> : <></>
            }
            onClick={() => this.setState({ isModalEditFolder: true })}
          >
            <Row>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Nom : <b>{this.props.agencyName}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  E-mail : <b>{this.props.agencyEmail}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card
            className="h-100"
            title="Réparateur "
            action={
              this.props.status < 4 ? <FontAwesomeIcon icon={faPen} /> : <></>
            }
            onClick={() => this.setState({ isModalEditFolder: true })}
          >
            <Row>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Nom : <b>{this.props.repairerName}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Téléphone : <b>{this.props.repairerPhone}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  E-mail : <b>{this.props.repairerEmail}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card
            title="Assuré"
            action={
              this.props.status < 4 ? <FontAwesomeIcon icon={faPen} /> : <></>
            }
            onClick={() => this.setState({ isModalEditFolder: true })}
          >
            <Row>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Client :{" "}
                  <b>
                    {this.props.kind} {this.props.insuredName}
                  </b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  E-mail : <b>{this.props.insuredEmail}</b>
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-10">
                <p className="m-0">
                  Téléphone : <b>{this.props.insuredPhone}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>

        <ModalEditFolder
          onClose={(isReload) => {
            if (isReload) {
              this.props.getFolder();
            }
            this.setState({ isModalEditFolder: false });
          }}
          show={this.state.isModalEditFolder}
          folder={{
            id: this.props.id,
            reference: this.props.reference,
            agencyEmail: this.props.agencyEmail,
            licensePlate: this.props.licensePlate,
            brand: this.props.brand,
            model: this.props.model,
            kind: this.props.kind,
            insuredName: this.props.insuredName,
            insuredPhone: this.props.insuredPhone,
            insuredEmail: this.props.insuredEmail,
            repairerName: this.props.repairerName,
            repairerPhone: this.props.repairerPhone,
            repairerEmail: this.props.repairerEmail,
            company: this.props.company,
            dateAppointment: this.props.dateAppointment,
            hourAppointment: this.props.hourAppointment,
          }}
        />
      </Row>
    );
  }
}
