const configPeer = {
  host: "xpertvisio.fr",
  port: 8027,
  path: "/webrtc",
  config: {
    iceServers: [
      { urls: "stun:coturn.xpertvisio.fr" },
      {
        urls: "turn:coturn.xpertvisio.fr",
        username: "xpertvisio-coturn",
        credential: "mQfgd895NAbdaz5n",
      },
    ],
  },
};

export default configPeer;
