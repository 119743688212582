import React from "react";
import { Nav, Loader, Button } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";

import Folder from "../../components/folder/Folder";
import Contact from "../../components/folder/Contact";
import Picture from "../../components/folder/Picture";
import Historical from "../../components/folder/Historical";
import ModalEditFolderMessage from "../../components/folder/ModalEditFolderMessage";

import axiosConfig from "../../utils/axiosConfig";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      isLoadPictures: true,
      id: "",
      token: "",
      reference: "",
      status: "",
      date: "",
      agencyEmail: "",
      agencyName: "",
      licensePlate: "",
      brand: "",
      model: "",
      kind: "",
      insuredName: "",
      insuredPhone: "",
      insuredEmail: "",
      messageEmail: "",
      messageEmailRepairer: "",
      messageSMS: "",
      messageSMSRepairer: "",
      dateAppointment: "",
      hourAppointment: "",
      historical: [],
      pictures: [],
      isModalEditFolderMessage: false,
    };
  }

  componentDidMount() {
    this.getFolder();
  }

  // start getFolder

  getFolder = (isGetPictures = true) => {
    axiosConfig("folder/get-folder", {
      data: { token: this.props.match.params.token },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              isLoad: false,
              id: res.data.folder.id,
              token: this.props.match.params.token,
              reference: res.data.folder.reference,
              status: res.data.folder.status,
              date: res.data.folder.date,
              agencyEmail: res.data.folder.agencyEmail,
              agencyName: res.data.folder.agencyName,
              licensePlate: res.data.folder.licensePlate,
              brand: res.data.folder.brand,
              model: res.data.folder.model,
              kind: res.data.folder.kind,
              dateAppointment: res.data.folder.dateAppointment,
              hourAppointment: res.data.folder.hourAppointment,
              insuredName: res.data.folder.insuredName,
              insuredPhone: res.data.folder.insuredPhone,
              insuredEmail: res.data.folder.insuredEmail,
              repairerName: res.data.folder.repairerName,
              repairerPhone: res.data.folder.repairerPhone,
              repairerEmail: res.data.folder.repairerEmail,
              messageEmail: res.data.folder.messageEmail,
              messageEmailRepairer: res.data.folder.messageEmailRepairer,
              messageSMS: res.data.folder.messageSMS,
              messageSMSRepairer: res.data.folder.messageSMSRepairer,
              company: res.data.folder.company,
              historical: res.data.historical,
            },
            () => {
              if (isGetPictures) {
                this.getPictures();
              }
            }
          );
        } else {
          this.getFolderError();
        }
      })
      .catch(this.getFolderError);
  };

  getFolderError = () => {
    this.setState({ isLoad: false }, () => {
      alert("Une erreur est survenue");
      this.props.history.push("/home");
    });
  };

  // end getFolder

  // start updateStatusFolder

  updateStatusFolder = (status) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/update-status-folder", {
        data: {
          status,
          id: this.state.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              isLoad: false,
              status,
            });
          } else {
            this.updateStatusFolderError();
          }
        })
        .catch(this.updateStatusFolderError);
    });
  };

  updateStatusFolderError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez actualiser la page")
    );
  };

  // end updateStatusFolder

  // start getPictures

  getPictures = () => {
    this.setState({ isLoadPictures: true }, () => {
      axiosConfig("folder/get-pictures", {
        data: {
          id: this.state.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              isLoadPictures: false,
              pictures: res.data.pictures,
            });
          } else {
            this.getPicturesError();
          }
        })
        .catch(this.getPicturesError);
    });
  };

  getPicturesError = () => {
    this.setState({ isLoadPictures: false, pictures: [] });
  };

  // end getPictures

  // start deletePicture

  deletePicture = (key) => {
    let tmpPictures = this.state.pictures;
    const id = tmpPictures[key].id;
    tmpPictures.splice(key, 1);
    this.setState({ pictures: tmpPictures }, () => {
      axiosConfig("folder/delete-picture", {
        data: {
          id,
        },
      })
        .then((res) => {
          if (!res.data.success) {
            this.deletePictureError();
          }
        })
        .catch(this.deletePictureError);
    });
  };

  deletePictureError = () => {
    alert("Une erreur est survenue lors de la suppression de l'image");
  };

  // end deletePicture

  render() {
    return (
      <>
        <Nav
          title={`N° dossier ${this.state.reference}`}
          className="mb-10 mb-20-xl"
          back
          onBack={() => this.props.history.push("/home")}
        >
          {this.state.status >= 2 && this.state.status < 4 ? (
            <Button
              className="ml-5"
              text="Clôturer"
              onClick={() => this.updateStatusFolder(4)}
            >
              <FontAwesomeIcon icon={faLock} />
            </Button>
          ) : null}
          {this.state.status === 4 ? (
            <Button
              className="ml-5"
              text="Ouvrir"
              onClick={() => this.updateStatusFolder(2)}
            >
              <FontAwesomeIcon icon={faLockOpen} />
            </Button>
          ) : null}
        </Nav>
        <Row>
          <Col xs={12} md={6} lg={8} className="mb-10 mb-20-xl">
            <Folder
              id={this.state.id}
              reference={this.state.reference}
              status={this.state.status}
              date={this.state.date}
              agencyEmail={this.state.agencyEmail}
              agencyName={this.state.agencyName}
              licensePlate={this.state.licensePlate}
              brand={this.state.brand}
              model={this.state.model}
              kind={this.state.kind}
              insuredName={this.state.insuredName}
              insuredPhone={this.state.insuredPhone}
              insuredEmail={this.state.insuredEmail}
              repairerName={this.state.repairerName}
              repairerPhone={this.state.repairerPhone}
              repairerEmail={this.state.repairerEmail}
              company={this.state.company}
              dateAppointment={this.state.dateAppointment}
              hourAppointment={this.state.hourAppointment}
              getFolder={() => this.getFolder()}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-10 mb-20-xl">
            <Contact
              status={this.state.status}
              id={this.state.id}
              token={this.state.token}
              openModalEdit={() =>
                this.setState({
                  isModalEditFolderMessage: true,
                })
              }
              insuredPhone={this.state.insuredPhone}
              insuredEmail={this.state.insuredEmail}
              repairerPhone={this.state.repairerPhone}
              repairerEmail={this.state.repairerEmail}
              messageEmail={this.state.messageEmail}
              messageEmailRepairer={this.state.messageEmailRepairer}
              messageSMS={this.state.messageSMS}
              messageSMSRepairer={this.state.messageSMSRepairer}
              agencyEmail={this.state.agencyEmail}
              dateAppointment={this.state.dateAppointment}
              hourAppointment={this.state.hourAppointment}
              getFolder={() => this.getFolder()}
            />
          </Col>
          <Col xs={12} className="mb-10 mb-20-xl">
            <Picture
              isLoad={this.state.isLoadPictures}
              data={this.state.pictures}
              deletePicture={this.deletePicture}
            />
          </Col>
          <Col xs={12} className="mb-10 mb-20-xl">
            <Historical historical={this.state.historical} />
          </Col>
        </Row>
        <ModalEditFolderMessage
          onClose={(isReload) => {
            if (isReload) {
              this.getFolder();
            }
            this.setState({ isModalEditFolderMessage: false });
          }}
          show={this.state.isModalEditFolderMessage}
          id={this.state.id}
          messageEmail={this.state.messageEmail}
          messageEmailRepairer={this.state.messageEmailRepairer}
          messageSMS={this.state.messageSMS}
          messageSMSRepairer={this.state.messageSMSRepairer}
          licensePlate={this.state.licensePlate}
          brand={this.state.brand}
          model={this.state.model}
          kind={this.state.kind}
          insuredName={this.state.insuredName}
          repairerName={this.state.repairerName}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
