import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "ui-kit-ck-consultant/dist/index.css";
import "ui-kit-ck-consultant/css/style.css";
import "./index.css";

console.log(
  `Name : ${process.env.REACT_APP_NAME}\nVersion : ${process.env.REACT_APP_VERSION}\nDate last version : ${process.env.REACT_APP_DATE}`
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
