import React, { createRef } from "react";
import { Button, Loader } from "ui-kit-ck-consultant";
import Peer from "peerjs";
import {
  faPhoneSlash,
  faSyncAlt,
  faMicrophoneAltSlash,
  faMicrophoneAlt,
  faVideoSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import Camera from "../../components/general/Camera";
import ButtonIcon from "../../components/general/ButtonIcon";

import axiosConfig from "../../utils/axiosConfig";
import configPeer from "../../utils/configPeer";
import {
  constraints,
  reverseCamera,
  startCamera,
  stopCamera,
  updateAudio,
  updateVideo,
} from "../../utils/camera";

export default class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPeer: false,
      isCamera: false,
      isCall: false,
      isMuted: false,
      isCameraDisabled: false,
      id: null,
      localVideoWidth: null,
      localVideoHeight: null,
      remoteVideoWidth: null,
      remoteVideoHeight: null,
      countLoadPicture: 0,
      isTakePicture: false,
      default: "",
      idUser: null,
      statusConnection: 0,
    };

    // WebRTC constraints
    this.constraints = constraints;
    // Components ref
    this.cameraRef = createRef();
    this.localVideoRef = createRef();
    this.remoteVideoRef = createRef();
  }

  setDefaultState = (isPeer, callback = () => {}) => {
    this.setState(
      {
        isPeer: isPeer,
        isCamera: false,
        isCall: false,
        isMuted: false,
        isCameraDisabled: false,
        id: null,
        localVideoWidth: null,
        localVideoHeight: null,
        remoteVideoWidth: null,
        remoteVideoHeight: null,
        countLoadPicture: this.state.countLoadPicture,
        isTakePicture: false,
        statusConnection: 0,
      },
      callback
    );
  };

  componentDidMount() {
    this.getFolderClient();
  }

  // start getFolder

  getFolderClient = () => {
    axiosConfig("folder/get-folder-client", {
      data: { token: this.props.match.params.token },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              idUser: res.data.folder.idUser,
              isLoad: false,
            },
            () => {
              if (res.data.folder.status < 4) {
                this.startPeer();
              } else {
                alert("Le dossier est clôturé");
              }
            }
          );
        } else {
          this.getFolderClientError(res.data);
        }
      })
      .catch(this.getFolderClientError);
  };

  getFolderClientError = (err) => {
    console.log(err);
    this.setState({ isLoad: false }, () => {
      alert("Une erreur est survenue");
    });
  };

  // end getFolder

  // start insertPictureClient

  insertPictureClient = (data) => {
    axiosConfig("folder/insert-picture-client", {
      data: {
        base64: data,
        token: this.props.match.params.token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            { countLoadPicture: this.state.countLoadPicture - 1 },
            () => {
              this.conn.send({
                type: "return-picture",
                id: res.data.id,
              });
            }
          );
        } else {
          this.insertPictureClientError();
        }
      })
      .catch(this.insertPictureClientError);
  };

  insertPictureClientError = () => {
    this.setState(
      { isLoad: false, countLoadPicture: this.state.countLoadPicture - 1 },
      () => alert("Une erreur est survenue, veuillez actualiser la page")
    );
  };

  // end insertPictureClient

  startPeer = (callback = () => {}) => {
    this.peer = new Peer(`${this.props.match.params.token}_client`, configPeer);
    this.peer.on("open", () => {
      this.setState({ isPeer: true }, callback);
    });

    this.peer.on("call", (call) => {
      this.call = call;
      this.call.answer(window.stream);
      this.call.on("stream", (remoteStream) => {
        this.remoteVideoRef.current.srcObject = remoteStream;
        this.remoteVideoRef.current.addEventListener(
          "playing",
          this.playingRemoteEventListener,
          false
        );
      });

      this.call.peerConnection.oniceconnectionstatechange = () => {
        if (this.call.peerConnection.iceConnectionState === "disconnected") {
          this.setState({ statusConnection: 0 });
        } else if (
          this.call.peerConnection.iceConnectionState === "connected"
        ) {
          this.setState({ statusConnection: 2 });
        } else if (
          this.call.peerConnection.iceConnectionState === "completed"
        ) {
          this.setState({ statusConnection: 2 });
        }
      };
    });

    this.peer.on("disconnect", () => {
      console.log("disconnect");
      // this.setState({ isPeer: false });
    });
    this.peer.on("error", (err) => {
      console.log("error", err);
      // this.setState({ isPeer: false });
    });
  };

  connectPeer = () => {
    this.conn = this.peer.connect(`xpertvisio_${this.state.idUser}_expert`);

    this.conn.on("open", () => {
      this.conn.on("data", (data) => {
        switch (data.type) {
          case "reject-call":
            alert("L'expert est indisponible");
            this.handleStopCameraClick();
            break;
          case "close-call":
            this.handleStopCameraClick();
            break;
          case "take-picture":
            this.handleTakePictureClick();
            break;
          case "reverse-camera":
            this.handleReverseCameraClick();
            break;
          case "point-marker":
            this.handlePointMarkerClick(
              data.x,
              data.y,
              data.width,
              data.height
            );
            break;
          case "clear-marker":
            this.handleClearMarkerClick();
            break;
          default:
            break;
        }
      });

      this.conn.send({
        type: "call",
        token: this.props.match.params.token,
      });
    });
  };

  playingLocalEventListener = () => {
    this.setState(
      {
        localVideoWidth: this.localVideoRef.current.videoWidth,
        localVideoHeight: this.localVideoRef.current.videoHeight,
      },
      () => {
        if (!this.state.isCall) {
          this.connectPeer();
        }
      }
    );
  };

  playingRemoteEventListener = () => {
    this.setState(
      {
        remoteVideoWidth: this.remoteVideoRef.current.videoWidth,
        remoteVideoHeight: this.remoteVideoRef.current.videoHeight,
      },
      () => this.setState({ isCall: true })
    );
  };

  handleStartClick = (callback) => {
    startCamera(this.constraints, (result) => {
      if (result.success) {
        this.setState({ isCamera: true }, () => {
          window.stream = result.stream;
          this.localVideoRef.current.srcObject = result.stream;
          this.localVideoRef.current.addEventListener(
            "playing",
            this.playingLocalEventListener,
            false
          );
        });
      } else {
        alert(JSON.stringify(result))
      }
    });
  };

  handleStopCameraClick = (isSend = false) => {
    stopCamera(() => {
      this.setDefaultState(true, () => {
        if (isSend) {
          if (this.conn) this.conn.send({ type: "close-call" });
        } else {
          if (this.call) this.call.close();
          if (this.conn) this.conn.close();
        }
      });
    });
  };

  handleUpdateAudioClick = () => {
    updateAudio(this.state.isMuted);
    this.setState({
      isMuted: !this.state.isMuted,
    });
  };

  handleUpdateVideoClick = () => {
    updateVideo(this.state.isCameraDisabled);
    this.setState({
      isCameraDisabled: !this.state.isCameraDisabled,
    });
  };

  handleReverseCameraClick = () => {
    reverseCamera(this.constraints, (result) => {
      this.constraints = result;
      startCamera(this.constraints, (result) => {
        if (result.success) {
          this.setState({ isCamera: true }, () => {
            window.stream = result.stream;
            this.localVideoRef.current.srcObject = result.stream;
            let sender = this.call.peerConnection
              .getSenders()
              .find(function (s) {
                return s.track.kind === "audio";
              });
            if (sender) {
              sender.replaceTrack(window.stream.getAudioTracks()[0]);
            }
            sender = this.call.peerConnection.getSenders().find(function (s) {
              return s.track.kind === "video";
            });
            if (sender) {
              sender.replaceTrack(window.stream.getVideoTracks()[0]);
            }
            if (this.state.isMuted) {
              this.setState({ isMuted: false }, this.handleUpdateAudioClick);
            } else {
              this.setState({ isMuted: false });
            }
          });
        }
      });
    });
  };

  handleTakePictureClick = () => {
    const date = moment().format("DD/MM/YYYY HH:mm:ss");

    let canvas = document.createElement("canvas");

    let width = 0;
    let height = 0;

    if (
      this.localVideoRef.current.videoHeight >
      this.localVideoRef.current.videoWidth
    ) {
      width = 720;
      height =
        (720 * this.localVideoRef.current.videoHeight) /
        this.localVideoRef.current.videoWidth;
    } else {
      width =
        (720 * this.localVideoRef.current.videoWidth) /
        this.localVideoRef.current.videoHeight;
      height = 720;
    }

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(this.localVideoRef.current, 0, 0, width, height);
    ctx.drawImage(this.cameraRef.current.getCanvasPNG(), 0, 0, width, height);
    ctx.font = "16px Arial";
    ctx.fillStyle = "red";
    ctx.fillText(date, 20, 40);
    const data = canvas.toDataURL('image/jpeg', 0.8);
    this.setState({ isTakePicture: false }, () =>
      this.setState(
        {
          isTakePicture: true,
          countLoadPicture: this.state.countLoadPicture + 1,
        },
        () => {
          this.insertPictureClient(data);
        }
      )
    );
  };

  handlePointMarkerClick = (x, y, width, height) => {
    this.cameraRef.current.addMarker(x, y, width, height);
  };

  handleClearMarkerClick = () => {
    this.cameraRef.current.clearCanvas();
  };

  render() {
    return (
      <>
        <div
          style={{
            flexDirection: "column",
          }}
          className="p-20 d-flex h-100 w-100"
        >
          <div className="mt-auto mb-auto">
            <Button
              className="m-auto"
              text="Démarrer la visioconférence"
              onClick={() => this.handleStartClick()}
              disabled={!this.state.isPeer}
            />
            <p style={{ textAlign: "center" }}>{this.state.default}</p>
          </div>
          {this.state.isCamera ? (
            <Camera
              ref={this.cameraRef}
              firstVideoWidth={this.state.localVideoWidth}
              firstVideoHeight={this.state.localVideoHeight}
              firstVideoRef={this.localVideoRef}
              firstVideoIsMuted={true}
              secondVideoWidth={this.state.remoteVideoWidth}
              secondVideoHeight={this.state.remoteVideoHeight}
              secondVideoRef={this.remoteVideoRef}
              countLoadPicture={this.state.countLoadPicture}
              isTakePicture={this.state.isTakePicture}
              statusConnection={this.state.statusConnection}
            >
              <ButtonIcon
                onClick={() => this.handleStopCameraClick(true)}
                icon={faPhoneSlash}
                red={true}
              />
              <ButtonIcon
                onClick={this.handleUpdateAudioClick}
                red={this.state.isMuted}
                icon={
                  this.state.isMuted ? faMicrophoneAltSlash : faMicrophoneAlt
                }
              />

              <ButtonIcon
                onClick={this.handleUpdateVideoClick}
                red={this.state.isCameraDisabled}
                icon={this.state.isCameraDisabled ? faVideoSlash : faVideo}
              />
              <ButtonIcon
                onClick={this.handleReverseCameraClick}
                icon={faSyncAlt}
              />
            </Camera>
          ) : null}
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
