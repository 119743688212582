import React from "react";
import {
  Modal,
  FormInput,
  FormSelect,
  Button,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faSave } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";
import { getCompaniesOptions, getCompanyFromCode } from "../../utils/general";

export default class ModalEditFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      agencyNumero: "",
      agencyEmail: "",
      licensePlate: "",
      insuredName: "",
      insuredPhone: "",
      insuredEmail: "",
      repairerName: "",
      repairerPhone: "",
      repairerEmail: "",
      brand: "",
      model: "",
      kind: "",
      company: "0",
      dateAppointment: "",
      hourAppointment: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        reference: this.props.folder.reference,
        agencyNumero: this.props.folder.agencyNumero,
        agencyEmail: this.props.folder.agencyEmail,
        licensePlate: this.props.folder.licensePlate,
        insuredName: this.props.folder.insuredName,
        insuredPhone: this.props.folder.insuredPhone,
        insuredEmail: this.props.folder.insuredEmail,
        repairerName: this.props.folder.repairerName,
        repairerPhone: this.props.folder.repairerPhone,
        repairerEmail: this.props.folder.repairerEmail,
        brand: this.props.folder.brand,
        model: this.props.folder.model,
        kind: this.props.folder.kind,
        company: this.props.folder.company,
        dateAppointment: this.props.folder.dateAppointment,
        hourAppointment: this.props.folder.hourAppointment,
      });
    }
  }

  // start getSynchronizationAlphaExpert

  getSynchronizationAlphaExpert = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/get-synchronization-alpha-expert", {
        data: {
          reference: this.state.reference,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              isLoad: false,
              agencyNumero: res.data.folder.agencyNumero
                ? res.data.folder.agencyNumero
                : "",
              agencyEmail: res.data.folder.agencyEmail
                ? res.data.folder.agencyEmail
                : "",
              licensePlate: res.data.folder.licensePlate
                ? res.data.folder.licensePlate
                : "",
              insuredName: res.data.folder.insuredName
                ? res.data.folder.insuredName
                : "",
              insuredPhone: res.data.folder.insuredPhone
                ? res.data.folder.insuredPhone
                : "",
              insuredEmail: res.data.folder.insuredEmail
                ? res.data.folder.insuredEmail
                : "",
              brand: res.data.folder.brand ? res.data.folder.brand : "",
              model: res.data.folder.model ? res.data.folder.model : "",
              kind: res.data.folder.kind ? res.data.folder.kind : "",
              company: getCompanyFromCode(res.data.folder.company),
              dateAppointment: res.data.folder.dateAppointment
                ? res.data.folder.dateAppointment
                : "",
              hourAppointment: res.data.folder.hourAppointment
                ? res.data.folder.hourAppointment
                : "",
            });
          } else {
            this.getSynchronizationAlphaExpertError();
          }
        })
        .catch(this.getSynchronizationAlphaExpertError);
    });
  };

  getSynchronizationAlphaExpertError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez réssayer")
    );
  };

  // end getSynchronizationAlphaExpert

  // start updateFolder

  updateFolder = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/update-folder", {
        data: {
          id: this.props.folder.id,
          reference: this.state.reference,
          agencyNumero: this.state.agencyNumero,
          agencyEmail: this.state.agencyEmail,
          licensePlate: this.state.licensePlate,
          insuredName: this.state.insuredName,
          insuredPhone: this.state.insuredPhone,
          insuredEmail: this.state.insuredEmail,
          repairerName: this.state.repairerName,
          repairerPhone: this.state.repairerPhone,
          repairerEmail: this.state.repairerEmail,
          brand: this.state.brand,
          model: this.state.model,
          kind: this.state.kind,
          company: this.state.company,
          dateAppointment: this.state.dateAppointment,
          hourAppointment: this.state.hourAppointment,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Le dossier a été modifié");
                this.props.onClose(true);
              }
            );
          } else {
            this.updateFolderError();
          }
        })
        .catch(this.updateFolderError);
    });
  };

  updateFolderError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez réssayer")
    );
  };

  // end updateFolder

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title={`Modifier le dossier ${this.state.reference}`}
          large
        >
          <Row>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Informations
              </p>
            </Col>
            <Col xs={12} md={5}>
              <FormInput
                className="mb-20"
                title="N° dossier"
                value={this.state.reference}
                name="reference"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={7} className="d-flex">
              <Button
                className="mt-auto mb-20"
                text="Synchronisation Alpha Expert"
                disabled={!this.state.reference}
                onClick={this.getSynchronizationAlphaExpert}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </Col>

            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Marque"
                value={this.state.brand}
                name="brand"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Modèle"
                value={this.state.model}
                name="model"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Immatriculation"
                value={this.state.licensePlate}
                name="licensePlate"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="Date RDV"
                type="date"
                value={this.state.dateAppointment}
                name="dateAppointment"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="Heure RDV"
                value={this.state.hourAppointment}
                name="hourAppointment"
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-10 ">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Agence
              </p>
            </Col>
            <Col xs={12} md={6}>
              <FormSelect
                title="Compagnie"
                value={this.state.company}
                name="company"
                ignore={true}
                options={getCompaniesOptions()}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="E-mail agence"
                value={this.state.agencyEmail}
                name="agencyEmail"
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Assuré
              </p>
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Titre"
                value={this.state.kind}
                name="kind"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={8}>
              <FormInput
                className="mb-20"
                title="Client"
                value={this.state.insuredName}
                name="insuredName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={5}>
              <FormInput
                className="mb-20"
                title="Téléphone"
                value={this.state.insuredPhone}
                name="insuredPhone"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={7}>
              <FormInput
                className="mb-20"
                title="E-mail"
                value={this.state.insuredEmail}
                name="insuredEmail"
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Réparateur
              </p>
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="E-mail du garage"
                value={this.state.repairerEmail}
                name="repairerEmail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Nom du garage"
                value={this.state.repairerName}
                name="repairerName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Numéro du garage"
                value={this.state.repairerPhone}
                name="repairerPhone"
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Button
            className="mb-20"
            text="Enregistrer"
            disabled={
              !this.state.reference ||
              !this.state.licensePlate ||
              !this.state.insuredName ||
              !this.state.insuredPhone ||
              !this.state.insuredEmail ||
              !this.state.brand ||
              !this.state.model ||
              !this.state.kind ||
              !this.state.dateAppointment ||
              !this.state.hourAppointment
            }
            onClick={this.updateFolder}
          >
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
