import React from "react";
import { Card } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

export default class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadPictures = () => {
    this.props.data.map((element, key) => {
      let a = document.createElement("a");
      a.href = element.base64;
      a.download = `${element.token}.${element.type.split("/")[1]}`;
      a.click();
      return null
    });
  };

  render() {
    return this.props.data.length || this.props.isLoad ? (
      <Card
        title="Photos"
        className="h-100"
        action={<FontAwesomeIcon icon={faDownload} />}
        onClick={this.downloadPictures}
        isLoader={this.props.isLoad}
      >
        <Row>
          {this.props.data.map((element, key) => (
            <Col key={key} xs={12} md={3} lg={2} className="mb-10">
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    padding: "1rem",
                    top: 0,
                    right: 0,
                    borderEndStartRadius: "10px",
                    cursor: "pointer",
                  }}
                  className="red"
                  onClick={() => this.props.deletePicture(key)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                <img
                  style={{ maxWidth: "100%", borderRadius: "10px" }}
                  src={element.base64}
                  alt={`car-${key}`}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Card>
    ) : null;
  }
}
