import React from "react";
import {
  Modal,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  Loader,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faPlus,
  faEnvelope,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";
import { getCompaniesOptions, getCompanyFromCode } from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class ModalEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      agencyNumero: "",
      agencyEmail: "",
      agencyName: "",
      licensePlate: "",
      brand: "",
      model: "",
      kind: "",
      insuredName: "",
      insuredPhone: "",
      insuredEmail: "",
      repairerName: "",
      repairerPhone: "",
      repairerEmail: "",
      dateAppointment: "",
      hourAppointment: "",
      company: "0",
      isDefaultMessageEmail: true,
      isDefaultMessageSMS: true,
      messageEmail: "",
      messageEmailRepairer: "",
      messageSMS: "",
      messageSMSRepairer: "",
      isProgramm: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "messageEmail" && this.state.isDefaultMessageEmail) {
          this.setState({
            isDefaultMessage: false,
          });
        } else if (name === "messageEmail" && !this.state.messageEmail) {
          this.setState(
            {
              isDefaultMessageEmail: true,
            },
            this.setDefaultMessageEmail
          );
        } else {
          this.setDefaultMessageEmail();
        }

        if (name === "messageSMS" && this.state.isDefaultMessageSMS) {
          this.setState({
            isDefaultMessage: false,
          });
        } else if (name === "messageSMS" && !this.state.messageSMS) {
          this.setState(
            {
              isDefaultMessageSMS: true,
            },
            this.setDefaultMessageSMS
          );
        } else {
          this.setDefaultMessageSMS();
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  setDefaultMessageEmail = () => {
    const defaultMessageEmail = `Bonjour ${this.state.kind} ${
      this.state.insuredName
    },

Nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.state.brand
    } ${this.state.model} ${this.state.licensePlate} en visioconférence : [URL]

Cordialement,
${this.context.name}
${this.context.groupName ? this.context.groupName : ""}`;

    const defaultMessageEmailRepairer = `Bonjour ${this.state.repairerName},

Nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.state.brand
    } ${this.state.model} ${this.state.licensePlate} en visioconférence : [URL]

Cordialement,
${this.context.name}
${this.context.groupName ? this.context.groupName : ""}`;

    this.setState({
      messageEmail: defaultMessageEmail,
      messageEmailRepairer: defaultMessageEmailRepairer,
    });
  };

  setDefaultMessageSMS = () => {
    const defaultMessageSMS = `Bonjour ${this.state.kind} ${
      this.state.insuredName
    }, nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.state.brand
    } ${this.state.model} ${
      this.state.licensePlate
    } en visioconférence : [URL] . Cordialement, ${this.context.name} ${
      this.context.groupName ? this.context.groupName : ""
    }`;

    const defaultMessageSMSRepairer = `Bonjour ${
      this.state.repairerName
    } , nous vous invitons à cliquer sur le lien ci-dessous afin de participer à l'expertise de votre véhicule ${
      this.state.brand
    } ${this.state.model} ${
      this.state.licensePlate
    } en visioconférence : [URL] . Cordialement, ${this.context.name} ${
      this.context.groupName ? this.context.groupName : ""
    }`;
    this.setState({
      messageSMS: defaultMessageSMS,
      messageSMSRepairer: defaultMessageSMSRepairer,
    });
  };

  // start getSynchronizationAlphaExpert

  getSynchronizationAlphaExpert = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/get-synchronization-alpha-expert", {
        data: {
          reference: this.state.reference,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
                agencyNumero: res.data.folder.agencyNumero
                  ? res.data.folder.agencyNumero
                  : "",
                agencyEmail: res.data.folder.agencyEmail
                  ? res.data.folder.agencyEmail
                  : "",
                agencyName: res.data.folder.agencyName
                  ? res.data.folder.agencyName
                  : "",
                licensePlate: res.data.folder.licensePlate
                  ? res.data.folder.licensePlate
                  : "",
                brand: res.data.folder.brand ? res.data.folder.brand : "",
                model: res.data.folder.model ? res.data.folder.model : "",
                kind: res.data.folder.kind ? res.data.folder.kind : "",
                insuredName: res.data.folder.insuredName
                  ? res.data.folder.insuredName
                  : "",
                insuredPhone: res.data.folder.insuredPhone
                  ? res.data.folder.insuredPhone
                  : "",
                insuredEmail: res.data.folder.insuredEmail
                  ? res.data.folder.insuredEmail
                  : "",
                company: getCompanyFromCode(res.data.folder.company),
                dateAppointment: res.data.folder.dateAppointment
                  ? res.data.folder.dateAppointment
                  : "",
                hourAppointment: res.data.folder.hourAppointment
                  ? res.data.folder.hourAppointment
                  : "",
                repairerName: res.data.folder.repairerName
                  ? res.data.folder.repairerName
                  : "",
                repairerPhone: res.data.folder.repairerPhone
                  ? res.data.folder.repairerPhone
                  : "",
                repairerEmail: res.data.folder.repairerEmail
                  ? res.data.folder.repairerEmail
                  : "",
              },
              () => {
                if (this.state.isDefaultMessageEmail) {
                  this.setDefaultMessageEmail();
                }
                if (this.state.isDefaultMessageSMS) {
                  this.setDefaultMessageSMS();
                }
              }
            );
          } else {
            this.getSynchronizationAlphaExpertError();
          }
        })
        .catch(this.getSynchronizationAlphaExpertError);
    });
  };

  getSynchronizationAlphaExpertError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez réssayer")
    );
  };

  // end getSynchronizationAlphaExpert

  // start insertFolder

  insertFolder = (type) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("folder/insert-folder", {
          data: {
            reference: this.state.reference,
            agencyNumero: this.state.agencyNumero,
            agencyEmail: this.state.agencyEmail,
            agencyName: this.state.agencyName,
            licensePlate: this.state.licensePlate,
            brand: this.state.brand,
            model: this.state.model,
            kind: this.state.kind,
            insuredName: this.state.insuredName,
            insuredPhone: this.state.insuredPhone,
            insuredEmail: this.state.insuredEmail,
            repairerName: this.state.repairerName,
            repairerPhone: this.state.repairerPhone,
            repairerEmail: this.state.repairerEmail,
            company: this.state.company,
            messageEmail: this.state.messageEmail,
            messageEmailRepairer: this.state.messageEmailRepairer,
            messageSMS: this.state.messageSMS,
            messageSMSRepairer: this.state.messageSMSRepairer,
            dateAppointment: this.state.dateAppointment,
            hourAppointment: this.state.hourAppointment,
          },
        })
          .then((res) => {
            if (res.data.success) {
              if (type === 1) {
                // Send mail
                this.setState(
                  {
                    id: res.data.id,
                    token: res.data.token,
                  },
                  this.sendEmail
                );
              } else if (type === 2) {
                // Send SMS
                this.setState(
                  {
                    id: res.data.id,
                    token: res.data.token,
                  },
                  this.sendSMS
                );
              } else {
                this.resetState(() => {
                  alert("Le dossier a été créé");
                  this.props.onClose(true);
                });
              }
            } else {
              this.insertFolderError();
            }
          })
          .catch(this.insertFolderError);
      }
    );
  };

  insertFolderError = () => {
    this.setState({ isLoad: false }, () =>
      alert("Une erreur est survenue, veuillez réssayer")
    );
  };

  // end insertFolder

  // start sendEmail

  sendEmail = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/send-email", {
        data: {
          id: this.state.id,
          token: this.state.token,
          insuredEmail: this.state.insuredEmail,
          messageEmail: this.state.messageEmail,
          agencyEmail: this.state.agencyEmail,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.resetState(() => {
              alert("Le dossier a été créé et le mail a été envoyé");
              this.props.onClose(true);
            });
          } else {
            this.sendEmailError();
          }
        })
        .catch(this.sendEmailError);
    });
  };

  sendEmailError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end sendEmail

  // start sendSMS

  sendSMS = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("folder/send-sms", {
        data: {
          id: this.state.id,
          token: this.state.token,
          insuredPhone: this.state.insuredPhone,
          groupName: this.context.groupName,
          messageSMS: this.state.messageSMS,
          dateAppointment: this.state.dateAppointment,
          hourAppointment: this.state.hourAppointment,
          isProgramm: this.state.isProgramm,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.resetState(() => {
              alert("Le dossier a été créé et le SMS a été envoyé");
              this.props.onClose(true);
            });
          } else {
            this.sendSMSError();
          }
        })
        .catch(this.sendSMSError);
    });
  };

  sendSMSError = () => {
    this.setState({ isLoad: false }, () => alert("Une erreur est survenue."));
  };

  // end sendSMS

  resetState = (callback) => {
    this.setState(
      {
        isLoad: false,
        reference: "",
        agencyNumero: "",
        agencyEmail: "",
        agencyName: "",
        licensePlate: "",
        brand: "",
        model: "",
        kind: "",
        insuredName: "",
        insuredPhone: "",
        insuredEmail: "",
        repairerName: "",
        repairerPhone: "",
        repairerEmail: "",
        company: "",
        messageEmail: "",
        messageEmailRepairer: "",
        messageSMS: "",
        messageSMSRepairer: "",
      },
      callback
    );
  };

  checkInput = () => {
    return (
      !this.state.reference ||
      !this.state.agencyEmail ||
      !this.state.agencyName ||
      !this.state.licensePlate ||
      !this.state.insuredName ||
      !this.state.insuredPhone ||
      !this.state.insuredEmail ||
      !this.state.messageEmail ||
      !this.state.messageSMS ||
      !this.state.dateAppointment ||
      !this.state.hourAppointment ||
      !this.state.repairerName ||
      !this.state.repairerPhone
    );
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title="Nouveau dossier"
          large
        >
          <Row>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Informations
              </p>
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="N° dossier"
                value={this.state.reference}
                name="reference"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4} className="d-flex">
              <Button
                outline
                className="mt-auto mb-20"
                text="Synchronisation Alpha Expert"
                disabled={!this.state.reference}
                onClick={this.getSynchronizationAlphaExpert}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <FormSelect
                className="mb-20"
                title="Compagnie"
                value={this.state.company}
                name="company"
                ignore={true}
                options={getCompaniesOptions()}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Marque"
                value={this.state.brand}
                name="brand"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Modèle"
                value={this.state.model}
                name="model"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Immatriculation"
                value={this.state.licensePlate}
                name="licensePlate"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="Date RDV"
                type="date"
                value={this.state.dateAppointment}
                name="dateAppointment"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="Heure RDV"
                value={this.state.hourAppointment}
                name="hourAppointment"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Agence
              </p>
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="E-mail agence"
                value={this.state.agencyEmail}
                name="agencyEmail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                className="mb-20"
                title="Nom de l'agence"
                value={this.state.agencyName}
                name="agencyName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Assuré
              </p>
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Titre"
                value={this.state.kind}
                name="kind"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Client"
                value={this.state.insuredName}
                name="insuredName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Téléphone"
                value={this.state.insuredPhone}
                name="insuredPhone"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="E-mail"
                value={this.state.insuredEmail}
                name="insuredEmail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Réparateur
              </p>
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="E-mail du garage"
                value={this.state.repairerEmail}
                name="repairerEmail"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Nom du garage"
                value={this.state.repairerName}
                name="repairerName"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                className="mb-20"
                title="Numéro du garage"
                value={this.state.repairerPhone}
                name="repairerPhone"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col lg={12} className="mb-10">
              <p
                className="m-0 bold"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--primary-color)",
                  borderBottom: "1px solid var(--primary-color)",
                }}
              >
                Messages
              </p>
            </Col>
            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                title="Message E-mail"
                name="messageEmail"
                value={this.state.messageEmail}
                onChange={this.handleInputChange}
                rows={6}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormTextarea
                className="mb-20"
                title="Message SMS"
                name="messageSMS"
                value={this.state.messageSMS}
                onChange={this.handleInputChange}
                rows={6}
              />
            </Col>
            <Col xs={12}>
              <FormCheckbox
                className="mb-20"
                text="Envoi différé"
                name="isProgramm"
                checked={this.state.isProgramm}
                onChange={this.handleCheckboxChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <Button
                className="mb-20"
                text="Créer + mail"
                disabled={this.checkInput()}
                onClick={() => this.insertFolder(1)}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                className="mb-20"
                text="Créer + SMS"
                disabled={this.checkInput()}
                onClick={() => this.insertFolder(2)}
              >
                <FontAwesomeIcon icon={faSms} />
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                className="mb-20"
                text="Créer le dossier"
                disabled={this.checkInput()}
                onClick={() => this.insertFolder(0)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          </Row>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
