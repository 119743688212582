import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layout Types
import DashboardLayout from "./layouts/Dashboard";
import ClientLayout from "./layouts/Client";

// Route Views
import Home from "./views/dashboard/Home";
import Folder from "./views/dashboard/Folder";

import Client from "./views/client/Client";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/folder/:token",
    exact: true,
    layout: DashboardLayout,
    component: Folder,
  },
  {
    path: "/client/:token",
    exact: true,
    layout: ClientLayout,
    component: Client,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
